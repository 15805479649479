/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { USER_CATEGORY } from 'constant';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { getAuth } from 'services/identity.service';
import { checkIfUserVerified, getUserAccess, getUserAccessInfo } from 'services/user.service';
import { PAGE_ACCESS_MAPPING, hasAccess, hasReadAccess, hasWriteAccess } from "core/common/userAccess.js";
import common_moengage from "../../assets/mo_engage/common_moengage_data.json";
import dynamic from 'next/dynamic';
import Loading from 'components/Loader/Loading';

const ModalReadAccessPopup = dynamic(() => import('molecules/Modals/ModalReadAccessPopup'), { ssr: false, loading: () => <Loading type='layer' /> });


const userContext = createContext()

export function useUserContext() {
  return useContext(userContext)
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isUserVerified, setIsUserVerified] = useState({})
  const router = useRouter()
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  // const [userAccess, setUserAccess] = useState(null)
  const [userAccess, setUserAccess] = useState({}); // Good: Initialized to {}
  const [showReadAccessModal, setShowReadAccessModal] = useState(false);
  const [isRedirectUser, setIsRedirectUser] = useState(false);
  const auth = getAuth();
  const attributes = common_moengage.entity;
  const [isShowSearchResult, setIsShowSearchResult] = useState(true);
  const [consultationType, setConsultationType] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const typesOfUser = {
    1: attributes.user_type_action.user_key,
    2: attributes.user_type_action.doctor_key,
    3: attributes.user_type_action.clinic_key,
    4: attributes.user_type_action.student_key,
    6: attributes.user_type_action.chemist_key
  };

  const categoriesOfUser = {
    0: attributes.user_category_action.website_key,
    1: attributes.user_category_action.iron_key,
    2: attributes.user_category_action.silver_key,
    3: attributes.user_category_action.gold_key,
    4: attributes.user_category_action.diamond_key,
    5: attributes.user_category_action.platinum_key
  };

  const updateUser = async () => {
    const userId = auth?.user?.id;
    if (userId) {
      setIsUserLoggedIn(true);
      const response = await checkIfUserVerified(userId)
      setIsUserVerified(response)
      setUser({
        ...user,
        userCategory: USER_CATEGORY[response?.entity?.doctor_category],
        is_verified: response?.entity?.is_verified,
        profile_pic: response?.entity?.profile_pic,
        userType: response?.entity?.userType,
        reason: response?.entity?.reason,
      });
      Moengage?.add_user_attribute(attributes.user_attributes_click.user_type_key, typesOfUser[response?.entity?.userType]);
      Moengage?.add_user_attribute(attributes.user_attributes_click.user_category_key, categoriesOfUser[response?.entity?.doctor_category]);

      const userAccessResponse = await getUserAccessInfo(userId)
      if (userAccessResponse?.status) {
        setUserAccess(userAccessResponse?.entity)
      }
    } else {
      setUserAccess(null)
      setIsUserLoggedIn(false);
    }
  }

  const getUserAccess = async (userId) => {
    const responseUserAccess = await getUserAccessInfo(userId || user?.user?.id)
    return responseUserAccess;
  }

  useEffect(() => {
    updateUser();
  //   updateUser().then(() => setIsLoading(false));
  }, [router.asPath, auth?.user?.id])

  const preventActionForReadAccess = ({ isForDeepLink = false, access, userToBeRedirected = false }) => {
    if (hasReadAccess(access)) {
      if (userToBeRedirected) {
        setIsRedirectUser(true);
        router.replace("/");
      }
      setShowReadAccessModal(true);
      return true;
    }
  };

  useEffect(() => {
    const { section, accessKey } = PAGE_ACCESS_MAPPING[router.pathname] || {};
    const pageAccessKey = userAccess?.[section]?.[accessKey];
    const isRedirectionBashOnUserType = pageAccessKey ? !hasAccess(pageAccessKey) : false;
    setIsRedirectUser(isRedirectionBashOnUserType);
    if (isRedirectionBashOnUserType) {
      router.replace("/");
    } else {
      preventActionForReadAccess({ access: pageAccessKey, userToBeRedirected: true });
    }
    setIsLoading(auth && userAccess && Object?.keys(userAccess).length === 0);

  }, [userAccess, router.asPath])


  return (
    <userContext.Provider value={{ user, updateUser, isUserLoggedIn, isUserVerified: isUserVerified, getUserAccess, userAccess, preventActionForReadAccess, isShowSearchResult, setIsShowSearchResult, consultationType, setConsultationType }}>
      {showReadAccessModal && <ModalReadAccessPopup showModalFor={""} isModalOpen={true} closeModal={() => setShowReadAccessModal(false)} popUpData={userAccess?.alert} />}
      {isLoading ? <Loading type="layer" /> : children} 
    </userContext.Provider>
  )
}

