import { getAuth } from "services/identity.service"

// 0: Pending, 1: UnderReview, 2: Approved, 3: Rejected
export const NOT_ALLOWED_USER_VERIFICATION_STATUS = {
    6: [0, 1, 2, 4],
    4: [0, 2, 4],
    2: [0, 2, 4],
}

export const councilTypeOption = [{
    label: "Central",
    value: 1
}, {
    label: "State",
    value: 2
}]

export const USER_TYPES = {
    ALL_USERS: [2, 4, 6],
    PATIENT: 1,
    DOCTORS: 2,
    STUDENTS: 4,
    RETAILER: 5,
    CHEMIST: 6,
    DOCTOR_DISTRIBUTOR: 7,
    CHEMIST_DISTRIBUTOR: 8
}

const auth = getAuth();

export const Profession = [
    { value: 1, label: "Individual/Patient", tooltip: "For premier ayurvedic consultations, therapies, treatments and medicines, etc." },
    { value: 2, label: "Doctor", tooltip: "For B.A.M.S. degree holders or those holding M.D./M.S./Ph.D." },
    { value: 4, label: "Student", tooltip: "For B.A.M.S / U.G. students or undertaking internships." },
    { value: 5, label: "Retailer", tooltip: "For retail shop owners & operators." },
    { value: 6, label: "Chemist", tooltip: "For medical store, Ayurveda Pharmacy, Hospital owners & operators." },
]

export const LoginProfession = [
    { value: 1, label: "Individual/Patient", tooltip: "Those who are looking for top ayurveda consultations, therapies & medicines." },
    { value: 2, label: "Doctor/Student/Chemist/Retailer", tooltip: "Doctors with B.A.M.S. degrees, Chemists who own Ayurveda Pharmacy, or students pursuing B.A.M.S." },
]

export const title = [
    { value: 1, label: "Dr." },
    { value: 2, label: "Mr." },
    { value: 3, label: "Mrs." },
    { value: 4, label: "Miss." },
]

export const SALUTATION = {
    1: "Dr.",
    2: "Mr.",
    3: "Mrs.",
    4: "Miss.",
}

export const chemistDocumentType = [
    { value: 1, label: "PAN" },
    { value: 2, label: "GST" }
]


export const returnTypeList = [
    { value: "fullReturn", label: "Full Return" },
    { value: "partialReturn", label: "Partial Return" }
]

export const CUSTOMER_TYPE = {
    INDIVIDUAL: 'INDIVIDUAL',
    PRACTITIONER: 'PRACTITIONER',
    INDIVIDUAL_LOGIN: 'INDIVIDUAL_LOGIN',
    PRACTITIONER_LOGIN: 'PRACTITIONER_LOGIN',
    CHEMIST_LOGIN: 'CHEMIST_LOGIN',
}

const companyFooterSection = {
    mainHeading: 'Company',
    automation: 'main-heading-company',
    list: [
        {
            "label": "About Us",
            "route": "/about-us",
            "automation": "about-us",
        },
        {
            "label": "Privacy Policy",
            "route": "/privacy-policy",
            "automation": "privacy-policy",
        },
        {
            "label": "Terms of Service",
            "route": "/terms-of-service",
            "automation": "terms-of-service",
        },
        {
            "label": "Cancellation Policy",
            "route": "/cancellation-policy",
            "automation": "cancellation-policy",
        },
        {
            "label": "Refunds & Returns Policy",
            "route": "/refunds-returns-policy",
            "automation": "refunds-and-returns-policy",
        },
        {
            "label": "Support",
            "route": "/support",
            "automation": "support",
        },
    ]
}
{
    if(![USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER, USER_TYPES?.CHEMIST].includes(auth?.user?.userType))
        companyFooterSection?.list?.splice(1, 0, {
            "label": "FAQs",
            "route": "/#faq",
            "automation": "footer-faq",
        },);
}

const footerLinksForIndividual = [{
    mainHeading: 'Offerings',
    automation: 'main-heading-for-individuals',
    list: [
        {
            "label": "Find Doctors",
            "route": "/ayurveda/doctors",
            "automation": "footer-find-doctor",
        },
        {
            "label": "Find Clinics",
            "route": "/ayurveda/clinics",
            "automation": "footer-clinics",
        },
        {
            "label": "Buy Medicines",
            "route": "/health-conditions",
            "automation": "footer-buymedicines",
        },
        {
            "label": "Treatments",
            "route": "/services/panchakarma",
            "automation": "footer-treatments-services",
        },
    ]
},
{
    mainHeading: 'For Doctors/Chemists',
    automation: 'main-heading-resources',
    list: [
        {
            "label": "Buy Medicines",
            "route": "/medicines",
            "automation": "footer-medicines",
        },
        {
            "label": "Nirog Partner",
            "route": "/reseller",
            "automation": "footer-reseller",
        },
        {
            "label": "Vaidya Tool",
            "route": "/login-practitioner",
            "automation": "footer-login-practitioner",
        },
        {
            "label": "Feed",
            "route": "/feeds",
            "automation": "footer-feeds",
        },
        {
            "label": "Learning",
            "route": "/learning",
            "automation": "footer-learning",
        },
        {
            "label": "Webinar",
            "route": "/webinar",
            "automation": "footer-webinar",
        },
        {
            "label": "Quiz",
            "route": "/quizzes",
            "automation": "footer-quizzes",
        },
    ]
},
{
    mainHeading: 'Resources',
    automation: 'main-heading-resources',
    list: [
        {
            "label": "Health Blogs",
            "route": "/blogs",
            "automation": "footer-health-blogs",
        },
        {
            "label": "Find Doctors",
            "route": "/ayurveda/doctors",
            "automation": "footer-find-doctors",
        },
        {
            "label": "Find Clinics",
            "route": "/ayurveda/clinics",
            "automation": "footer-find-clinics",
        },
    ]
},
    companyFooterSection
];

export const footerLinksForPractitioner = [
    {
        mainHeading: 'For Individuals',
        automation: 'main-heading-for-individuals',
        list: [
            {
                "label": "Book Video Consultation",
                "route": "/ayurveda/doctors",
                "automation": "footer-book-appointment",
            },
            {
                "label": "Treatments",
                "route": "/treatments/pain-management",
                "automation": "footer-treatments",
            },
            {
                "label": "Services",
                "route": "/services/panchakarma",
                "automation": "footer-ayurveda-services",
            },
        ]
    },
    {
        mainHeading: 'For Doctors/Chemists',
        automation: 'main-heading-resources',
        list: [
            {
                "label": "Buy Medicines",
                "route": "/medicines",
                "automation": "footer-medicines",
            },
            {
                "label": "Nirog Partner",
                "route": "/reseller",
                "automation": "footer-reseller",
            },
            {
                "label": "Vaidya Tool",
                "route": "/",
                "automation": "footer-vaidya-tool",
            },
            {
                "label": "Feed",
                "route": "/feeds",
                "automation": "footer-feeds",
            },
            {
                "label": "Learning",
                "route": "/learning",
                "automation": "footer-learning",
            },
            {
                "label": "Webinar",
                "route": "/webinar",
                "automation": "footer-webinar",
            },
            {
                "label": "Quiz",
                "route": "/quizzes",
                "automation": "footer-quizzes",
            },
        ]
    },
    {
        mainHeading: 'Resources',
        automation: 'main-heading-resources',
        list: [
            {
                "label": "Health Blogs",
                "route": "/blogs",
                "automation": "footer-health-blogs",
            },
            {
                "label": "Find Doctors",
                "route": "/ayurveda/doctors",
                "automation": "footer-find-doctors",
            },
            {
                "label": "Find Clinics",
                "route": "/ayurveda/clinics",
                "automation": "footer-find-clinics",
            },
        ]
    },
    companyFooterSection
]

export const footerLinksForIndividualLogin = [
    {
        mainHeading: 'Offerings',
        automation: 'main-heading-Offerings',
        list: [
            {
                "label": "Book Video Consultation",
                "route": "/ayurveda/doctors",
                "automation": "footer-book-appointment",
            },
            {
                "label": "Treatments",
                "route": "/treatments/pain-management",
                "automation": "footer-treatments",
            },
            {
                "label": "Services",
                "route": "/services/panchakarma",
                "automation": "footer-ayurveda-services",
            },

        ]
    },
    {
        mainHeading: 'Resources',
        automation: 'main-heading-resources',
        list: [
            {
                "label": "Health Blogs",
                "route": "/blogs",
                "automation": "footer-health-blogs",
            },
            {
                "label": "Find Doctors",
                "route": "/ayurveda/doctors",
                "automation": "footer-find-doctors",
            },
            {
                "label": "Find Clinics",
                "route": "/ayurveda/clinics",
                "automation": "footer-find-clinics",
            },
        ]
    },
    companyFooterSection
]

export const footerLinksForPractitionerLogin = [
    {
        mainHeading: 'Offerings',
        automation: 'main-heading-resources',
        list: [
            {
                "label": "Buy Medicines",
                "route": "/medicines",
                "automation": "footer-medicines",
            },
            {
                "label": "Nirog Partner",
                "route": "/reseller",
                "automation": "footer-reseller",
            },
            {
                "label": "Vaidya Tool",
                "route": "/",
                "automation": "footer-vaidya-tool",
            },
            {
                "label": "Feed",
                "route": "/feeds",
                "automation": "footer-feeds",
            },
            {
                "label": "Learning",
                "route": "/learning",
                "automation": "footer-learning",
            },
            {
                "label": "Webinar",
                "route": "/webinar",
                "automation": "footer-webinar",
            },
            {
                "label": "Quiz",
                "route": "/quizzes",
                "automation": "footer-quizzes",
            },
        ]
    },
    {
        mainHeading: 'Resources',
        automation: 'main-heading-resources',
        list: [
            {
                "label": "Health Blogs",
                "route": "/blogs",
                "automation": "footer-health-blogs",
            },
            {
                "label": "Find Doctors",
                "route": "/ayurveda/doctors",
                "automation": "footer-find-doctors",
            },
            {
                "label": "Find Clinics",
                "route": "/ayurveda/clinics",
                "automation": "footer-find-clinics",
            },
        ]
    },

    companyFooterSection
]

export const footerLinksForChemistLogin = [
    {
        mainHeading: 'Offerings',
        automation: 'main-heading-Offerings',
        list: [
            {
                "label": "Buy Medicines",
                "route": "/medicines/allMedicines",
                "automation": "footer-medicines",
            },
        ]
    },

    companyFooterSection
]

export const footerLinks = {
    [CUSTOMER_TYPE.INDIVIDUAL]: footerLinksForIndividual,
    [CUSTOMER_TYPE.PRACTITIONER]: footerLinksForPractitioner,
    [CUSTOMER_TYPE.INDIVIDUAL_LOGIN]: footerLinksForIndividualLogin,
    [CUSTOMER_TYPE.PRACTITIONER_LOGIN]: footerLinksForPractitionerLogin,
    [CUSTOMER_TYPE.CHEMIST_LOGIN]: footerLinksForChemistLogin,
}

export const contactUs = {
    address: "5th Floor, Tower- 4, NBCC Plaza, Pushp Vihar, Saket, New Delhi- 110017",
    mobileNo: "+919319361976",
    email: "info@nirogstreet.com"
    
}

export const socialLinks = [
    {
        "iconType": "IconFacebook",
        "route": "https://www.facebook.com/NirogStreet/",
        "ariaLabel": "NirogStreet Facebook Link",
    },
    {
        "iconType": "IconTwitter",
        "route": "https://twitter.com/nirogstreet",
        "ariaLabel": "NirogStreet Twitter Link",
    },
    {
        "iconType": "IconLinkedin",
        "route": "https://www.linkedin.com/company/nirogstreetindia/",
        "ariaLabel": "NirogStreet Linkedin Link",
    },
    {
        "iconType": "IconYoutube",
        "route": "https://www.youtube.com/c/NirogStreet",
        "ariaLabel": "NirogStreet Youtube Link",
    }
]

export const genderOptions = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" }
]

export const genderOptionsV2 = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" },
]

export const salutaionOptions = [
    {
        label: 'Dr.',
        value: 1
    },
    {
        label: 'Mr.',
        value: 2
    },
    {
        label: 'Mrs.',
        value: 3
    },
    {
        label: 'Miss.',
        value: 4
    },
]

export const categoryOptions = [
    { value: 1, label: "Ayurveda" },
    { value: 6, label: "Yoga" },
    { value: 2, label: "Naturopathy" },
    { value: 4, label: "Unani" },
    { value: 3, label: "Siddha" },
    { value: 5, label: "Homeopathy" },
]

export const universalSearchOptions = [
    {
        value: 0,
        label: "Aggregation Query",
    },
    {
        value: 1,
        label: "Sequential",
    },
    {
        value: 2,
        label: "Concurrent",
    },
    {
        value: 3,
        label: "Only Products",
    },
];

export const webinarUserRole = {
    1: "host",
    2: "Co_host",
    3: "presenter",
    4: "Participant"
}

export const webinarPreset = {
    1: "Admin",
    2: "Co_host",
    3: "Presenter",
    4: "Participant",
}

export const searchbarVisibleFor = ['medicines', 'blogs', 'clinics', 'doctors']

export const USER_CATEGORY = {
    'website': 'WEB',
    'iron': 'IRON',
    'silver': 'SILVER',
    'gold': 'GOLD',
    'diamond': 'DIAMOND',
    'platinum': 'PLATINUM',
}
export const CATEGORY = {
    DEFAULT: { color: 'text-primary2-500', iconColor: 'text-primary2-500', gradient: 'bg-web' },
    IRON: { color: 'text-other-red-500', iconColor: 'text-other-red-500', gradient: 'bg-iron' },
    SILVER: { color: 'text-other-gray-500', iconColor: 'text-other-gray-500', gradient: 'bg-silver' },
    GOLD: { color: 'text-other-yellow-500', iconColor: 'text-other-yellow-500', gradient: 'bg-gold' },
    DIAMOND: { color: 'text-other-cerulean-500', iconColor: 'text-other-cerulean-500', gradient: 'bg-diamond' },
    PLATINUM: { color: 'text-other-purple-500', iconColor: 'text-other-purple-500', gradient: 'bg-platinum' },
}

export const couponInfo = '"Dear Patron, the applied coupon or nirog money will be removed as soon as you exit the payment page. Please make a purchase and enjoy exciting discounts."';
export const nirogMoneyApply = 'NirogMoney is not applicable to Kottakal medicines if added to your cart.';

export const typeOfSearch = {
    BookMarks: "BookMarks",
}

export const OLYMPIAD_START_DATE = 'December 8, 9AM till December 11, 9PM';
export const OLYMPIAD_ENDED_MSG = 'This event has ended. The leaderboard is no longer available.';

export const ACTIVE_FLAG = {
    SHOW: '1',
    HIDE: '0',
}

export const MEDIA_TYPE_FEED = {
    IMAGE: 'image',
    VIDEO: 'video',
    DOCUMENT: 'document',
}

export const DOWNLOAD_CERTIFICATE_CONTEXT = {
    QUIZ: 'QUIZ',
    WEBINAR: 'WEBINAR',
    LMS: 'LMS',
    OLYMPIAD: 'OLYMPIAD',
}
export const PAYMENT_METHOD = {
    CASH: 1,
    ONLINE: 2,
    NEFT: 4,
}

export const PAYMENT_METHOD_HEADINGS = {
    ONLINE: "You can choose to pay while placing the order via the “Pay online” option via your preferred UPI, net banking, e-wallet, credit or debit card. All online transactions are encrypted and secured. ",
    COD: "Payment on Delivery (POD) includes Cash on Delivery (COD) and other payment options like UPI / QR-code scanners and payment links. You can choose to pay the delivery partner via your preferred mode of payment at the time of delivery."
}

export const USER_CATEGORY_PDP = {
    0: "website",
    1: "iron",
    2: "silver",
    3: "gold",
    4: "diamond",
    5: "platinum",
    6: "reseller"
}

export const PAYMENT_OPTION_TYPE = {
    BANK_ACCOUNT: "bankAccount",
    UPI: "upiId"
}

export const ACCOUNT_VERIFICATION_STATUS = {
    INVALID: 0,
    VALID: 1,
    INCONCLUSIVE: 2
}
export const PAYMENT_METHODS = {
    ALl: 0,
    COD: 1,
    PREPAID: 2,
    POSTPAID: 4,
}
export const BLOG_TYPE = {
    "blogs": "1",
    "news": "2",
    "photos": "3",
    "videos": "4",
    "shorts": "5"
}
export const VALID_BLOG_URL = ["blogs", "news", "photos", "videos", "shorts"]
export const VALID_BLOG_LANG = ["en", "hi"]

export const HIDE_BOT_FOR = { '/bot': '', '/ai-assistant': '' }
export const INVENTORY_FOR_NON_VERIFY_USER_MSG = 'You need to be verified user in order to place orders.'

export const USER_ACCESS = {
    WRITE: 1,
    READ: 2,
    HIDE: 3,
}
export const NPS_MODULES = {
    "Webinars": 1,
    "Quiz": 2,
    "LMS": 3,
    "NirogPartnerOrders": 4,
    "HealthBlogs": 5,
    "B2BOrders": 6,
    "FindClinics": 7,
    "FindDoctors": 8,
    "Feeds": 9,
    "D2CAppointmentRequest": 10,
    "D2CAppointmentFeedback": 11,
    "D2COrders": 12,
    "Blogs": 13,
    "DoctorsFeedback": 14
};

export const NPS_CHANNELS = {
    "B2B": 1,
    "B2B2C": 2,
    "D2C": 3
};

export const USER_INPUT_DATA_TYPES = {
    number: "number",
    naturalNumber: "naturalNumber",
    wholeNumber: "wholeNumber",
    decimal: "decimal",
    email: "email",
    alphabets: "alphabets",
    alphanumeric: "alphanumeric",
    mobile: "mobileNumber",
    url: "url",
    noEmoji: "NoEmoji",
    general: "general",
    nonEmojiText: "nonEmojiText",
    pincode: "pincode",
};

export const DOCTOR_SEARCH = {
    ayurvedaDoctor: "Ayurveda Doctor"
}

export const ALL_MEDICINES = 'All Medicines'

export const CHANNEL = {
    "B2B": "B2B",
    "B2B2C": "B2B2C",
    "D2C": "D2C"
}

export const consultAnDoctorRedirection = {
    "diabetes-care": "Diabetes",
    "liver-care": "Liver%20Problems",
    "leukorrhea-care": "specs=Gynaecology",
    "hair-care": "Cosmetic%20Treatment"
}

export const SKIP_URL_SAVE_FOR =
    ['/blogs/', '/news/', '/photos/', '/videos/', '/shorts/',  '/ayurvedic-doctor','/ayurveda', '/medicine/', '/clinics/']


export const SKIP_CART_API_CALL_FOR =['/webinar/participant/[webinarId]', '/quiz']

export const DOCTOR_CONSULTATION_MODE = {
    VIDEO_CONSULTATION: 0,
    IN_CLINIC_CONSULTATION: 1,
};

export const APPOINTMENT_TYPE = {
    RESELLER_APPOINTMENT: 0,
    ONLINE_APPOINTMENT: 1,
    IN_CLINIC_APPOINTMENT: 2,
};

export const OPEN_AI_PROMPT_ACTIONS = {
    TRANSLITERATE: 1,
    TRANSLATE: 2,
};

export const ORDER_TYPE = {
    MEDICINE: 3,
    APPOINTMENT: 4,
    DROP_SHIPPING: 5
}

export const CUSTOMER_ENTITY_TYPE = {
    CUSTOMER_TICKET: 'CUSTOMER_TICKET',
}

export const USER_TYPE_INT = {
    1: "individual",
    2: "Doctor",
    4: "Student",
    5: "Retailer",
    6: "Chemist",
    7: "DoctorsDistributor",
    8: "ChemistDistributor"
};
