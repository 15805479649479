import { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from "next/router";

import { getAuth, getRedirectUrlForUserType, setAuth } from './../../services/identity.service';
import { checkIfUserVerified } from './../../services/user.service';
import common_moengage from "../../assets/mo_engage/common_moengage_data.json";
const verifyDocumentsModalContext = createContext()
import {useUserContext} from "./UserContext.js"
import { NOT_ALLOWED_USER_VERIFICATION_STATUS, USER_ACCESS } from 'constant';

import Loading from 'components/Loader/Loading';
import dynamic from 'next/dynamic';
const VerifyStickySlider = dynamic(() => import('../VerifyProfile/VerifyStickySlider'), { ssr: false });
const ModalVerifyDocumentPopUps = dynamic(() => import('molecules/Modals/ModalVerifyDocumentPopUps'), { ssr: false, loading: () => <Loading type='layer' /> });


export function useVerifyDocumentsModalContext() {
    return useContext(verifyDocumentsModalContext)
}

export const getVerifyMassege = ({ reason, verifyResponse }) => {
    switch (verifyResponse) {
        case 0:
            return "Dear Patron, Please provide additional information to validate your profile and continue to participate in engagement activities and purchase medicine."

        case 2:
            return "Your documents were submitted successfully,and we are reviewing it."

        case 4:
            return `Dear Patron, Your document verification is not approved, due to "${reason}". Click here to re-upload your documents`

        default:
            return null
    }
}

export const VerfiyDocumentsModalProvider = ({ children }) => {
    const auth = getAuth();
    const userId = auth?.user?.id || auth?.user?.user?.id;
    const user_is_verified = auth?.user?.tbl_doctor_profile?.is_verified
    const [alertMsg, setAlertMsg] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModalFor, setShowModalFor] = useState('');
    const [verifyResponse, setVerifyResponse] = useState(null);
    const [reason, setReason] = useState(null);
    const router = useRouter();
    const checkPath = router.pathname;
    const [isVerified, setIsVerified] = useState(null);
    const attributes = common_moengage.entity;
    const userIsVerifiedResponse = useUserContext().isUserVerified

    const statusOfVerification = {
        0: attributes.verification_action.un_verifiied_key,
        1: attributes.verification_action.verified_key,
        2: attributes.verification_action.in_verification_key,
        3: attributes.verification_action.re_Verified_key,
        4: attributes.verification_action.disapproved_key
    };

    const verifyStiky = isVerified ? isVerified : auth?.user?.tbl_doctor_profile?.is_verified

    const initializeValue = () => {
        if (!auth) return false;
        const user = auth?.user || auth?.user?.user;
        if (user) {
            return [0, 2, 4].includes(user?.tbl_doctor_profile?.is_verified)
        }
    }
    const settingValue = initializeValue();
    const [isModalVerifyDocuments, setIsModalVerifyDocuments] = useState(settingValue);

    useEffect(() => {
        if (userId) {
            const response = userIsVerifiedResponse
            setReason(response?.entity?.reason)
            updateIsVerified(response)
            setVerifyResponse(response?.entity?.is_verified)
        }
    }, [userIsVerifiedResponse])



    const updateIsVerified = async (response) => {
        if (userId) {
            if(!response){
                response = await checkIfUserVerified(userId)
            }
            if (response.status === true) {
                setAuth({
                    ...auth, user: {
                        ...auth?.user, isVerifyCheckedKey: response?.entity?.is_verified
                    }
                })
                setAuth({
                    ...auth, user: {
                        ...auth?.user,                         
                        couponCode: response?.entity?.couponCode,
                        couponOff: response?.entity?.couponOff,
                        userType: response?.entity?.userType,
                        tbl_doctor_profile: {
                            ...auth?.user.tbl_doctor_profile,
                            is_verified: response?.entity?.is_verified
                        }
                    }
                })
                setIsModalVerifyDocuments([0, 2, 4].includes(response?.entity?.is_verified))
                Moengage?.add_user_attribute(attributes.user_attributes_click.verification_status_key, statusOfVerification[response?.entity?.is_verified]);
                return response?.entity?.is_verified
            } else {
                setAlertMsg({ message: response?.entity?.is_verified, type: 'error' })
            }
        }
    }

    const verifyDocumentsStates = () => {
        const auth = getAuth()
        if (!auth) return false;
        const user = auth.user || auth.user.user;
        if (user) {
            return (user?.tbl_doctor_profile?.is_verified)
        }
    }

    const handlerModalClose = () => {
        setIsModalOpen(false);
    }

    const verificationstate = {
        0: 'verifyDocumentsPending',
        2: 'verifyDocumentsUnderReview',
        1: 'verifyDocumentsApproved',    // Regular Flow
        4: 'verifyDocumentsRejected',
    }

    const handlerSetShowModalFor = async ({ isForDeepLink = false } = {}) => {
        const checkPath = router.pathname;
        const user = auth?.user || auth?.user?.user;
        let response = await updateIsVerified();
        if (!([0, 1, 2, 4].includes(response))) {
            response = verifyDocumentsStates()
        }
        const isRedirectUrlForUserType = (getRedirectUrlForUserType() || isForDeepLink) && NOT_ALLOWED_USER_VERIFICATION_STATUS[user?.userType]?.includes(response);
        const modalContent = isRedirectUrlForUserType
            ? verificationstate[response] + 'UserType'
            : verificationstate[response]
        const conditionForShowModal = ['/feeds', '/feeds/[feedId]', '/quizzes', '/community', '/webinar', '/medicines', '/medicines/category/[id]', '/medicines/allMedicines', '/medicine/[...slug]', '/medicines/[slug]', '/profile-verification'].includes(checkPath) || checkPath.indexOf('reseller') !== -1
        if (isRedirectUrlForUserType && user?.userType === 6) {
            setShowModalFor(user?.userType === 6 ? 'verifyDocumentsChemistUser' : modalContent);
            setIsModalOpen(true)
        }
        else if ([0,2,4].includes(response)) {
            if (conditionForShowModal) {
                setShowModalFor(modalContent);
                setIsModalOpen(true)
            }
        } 
        // else if ([2, 4].includes(response)) {
        //     if (conditionForShowMOdal) {
        //         setShowModalFor(modalContent);
        //         setIsModalOpen(true)
        //     }
        // } 
        else {
            setIsModalVerifyDocuments(false)
            handlerModalClose();
        }
    }

    const hasWriteAccess = ({ isForDeepLink = false, access }) => {
        if (isModalVerifyDocuments && access === 2) {
            handlerSetShowModalFor({ isForDeepLink, access })
            return false;
        } else if (access === USER_ACCESS.WRITE) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <verifyDocumentsModalContext.Provider value={{ setIsVerified, isVerified, showModalFor, reason, setShowModalFor, handlerSetShowModalFor, setIsModalOpen, isModalVerifyDocuments: settingValue || isModalVerifyDocuments, isVerifyModalOpen: isModalOpen, massege: getVerifyMassege({ reason, verifyResponse }), hasWriteAccess }}>
            {verifyStiky === 0 && auth?.user?.userType != 1 ? ['/medicines', "/", "/feeds", "/profile"].includes(checkPath) && <VerifyStickySlider /> : null}
            {isModalOpen && <ModalVerifyDocumentPopUps showModalFor={showModalFor} reason={reason} isModalOpen={isModalOpen} handlerModalClose={handlerModalClose} />}
            {children}
        </verifyDocumentsModalContext.Provider>
    )
}
