import WithAuth from "@/components/Auth/WithAuth";
import { ModalProvider } from "@/components/context/ModalContext";
import { UserProvider } from "@/components/context/UserContext";
import { VerfiyDocumentsModalProvider } from "@/components/context/VerifiyDocumentsModalContext";
import { AddressProvider } from "@/components/context/addressContext.js";
import { CartProvider } from "@/components/context/cartContext";
import { ParticipateOlympiadProvider } from "@/components/context/signUpContext";
import Head from "next/head";
import NextNprogress from "nextjs-progressbar";
import { useEffect, useState } from "react";
import { getAuth, setAuth } from "services/identity.service";
import "../styles/globals.css";

import { OrderProvider } from "@/components/context/orderContext";
import Loading from "components/Loader/Loading";
import dynamic from "next/dynamic";
import { getResellerConstant } from "services/reseller.service";
import { HIDE_BOT_FOR } from "constant";
import ReactGA from "react-ga4";
import { decodedBufferBase64 } from "core";
import Script from "next/script";

const ModalForDeactivatedUser = dynamic(() => import("molecules/Modals/ModalForDeactivatedUser"), { ssr: false, loading: () => <Loading type="layer" /> });
const Custom404 = dynamic(() => import("pages/404"), { ssr: false, loading: () => <Loading type="layer" /> });

const baseUrl = process.env.NEXT_PUBLIC_ENV === "development" ? "http://localhost:4200/" : process.env.NEXT_PUBLIC_PAGE_BASE;

function MyApp({ Component, pageProps, router }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserActive, setIsUserActive] = useState(true);
  const [botInfo, setBotInfo] = useState(false);

  console.log = () => {};

  useEffect(() => {
    let auth = getAuth();
    if (!auth?.token && router?.query?.token && (router?.query?.id || router?.query?.userId)) {
      setAuth({
        token: router?.query?.token,
        user: { id: Buffer.from(router?.query?.id || router?.query?.userId, "base64").toString("utf-8") },
      });
    }
    if (!auth?.token && router?.query?.auth) {
      let userAuth = Buffer.from(router?.query?.auth, "base64").toString("utf-8");
      let getUserId = decodedBufferBase64(userAuth);
      const fetchUserId = getUserId.match(/"id":(\d+)/);
      let userId = Number(fetchUserId[1]);

      setAuth({ token: userAuth, user: { id: userId } });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      document.setIsUserActive = setIsUserActive;

      if (!(window?.location?.pathname in HIDE_BOT_FOR)) {
        let result = await getResellerConstant();
        if (result?.entity?.botInfo?.showChatBot) {
          setBotInfo(result.entity?.botInfo);
        }
      }
    };

    fetchData();
  }, []);

  ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);

  useEffect(() => {
    setIsModalOpen(!isUserActive);
  }, [isUserActive]);

  const initBotPress = ({ hideWidget = false } = {}) => {
    window.botpressWebChat.init({
      host: botInfo?.host,
      botId: botInfo?.botId,
      hideWidget,
      className: "!z-[1000]",
      extraStylesheet: baseUrl + "/css/bot-chat.css",
    });

    window.addEventListener("message", function (event) {
      if (event.data.name === "webchatReady") {
        window.botpressWebChat.sendEvent({
          type: "proactive-trigger",
          channel: "web",
          payload: { text: "AutoTrigger workflow" },
        });
      }
    });

    window.disableBot = () => initBotPress({ hideWidget: true }) 

    // global function to open chatbot
    window.openChatBot = () => {
      if (window.botpressWebChat) {
        window.botpressWebChat.sendEvent({ type: "toggle" });
      }
  };
  };

  return (
    <>
      <Head>
        <title>NirogStreet</title>
        <link rel="icon" href="favicon.ico" />
      </Head>

      <NextNprogress color="#24acda" startPosition={0.1} stopDelayMs={100} height={5} />
      {isModalOpen && <ModalForDeactivatedUser isModalOpen={isModalOpen} />}
      
      <WithAuth router={router}>
        <UserProvider>
          <ParticipateOlympiadProvider>
            <OrderProvider>
              <AddressProvider>
                <VerfiyDocumentsModalProvider>
                  <ModalProvider>
                    <CartProvider>
                      {pageProps.error ? <Custom404 /> : <Component {...pageProps} />}
                    </CartProvider>
                  </ModalProvider>
                </VerfiyDocumentsModalProvider>
              </AddressProvider>
            </OrderProvider>
          </ParticipateOlympiadProvider>
        </UserProvider>
      </WithAuth>

      {botInfo && <Script async={true}
        strategy="afterInteractive" src={`${botInfo?.host}/assets/modules/channel-web/inject.js`} onLoad={() => {
          initBotPress({hideWidget: true});
        }}
      ></Script>}
    </>
  );
}

export default MyApp;